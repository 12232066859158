const ROUTES = {
  ROOT_PATH: '/',
};

const DEFAULT_CONFIGS = {
  groupCode: 'dpae',
  brandCode: 'dtdr',
  language: 'en',
  currencyCode: 'THB',
  playerToken: 'efb2604ad7b46a9153ee0b52bb535ab3',
};

export { ROUTES, DEFAULT_CONFIGS };
