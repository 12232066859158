import React, { memo } from 'react';
import { useLanguageContext } from 'src/context/languageContext';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { getFlagPosition } from 'src/helpers';
import './index.scss';
import flagImage from 'src/assets/images/sprite-flags.png';

const LanguageSwitch = memo((props) => {
  const { languages, i18n, setI18n, setLocalStorageLocale } =
    useLanguageContext();

  const title = (
    <>
      <div
        className="flag"
        style={{
          background: `url(${flagImage}) no-repeat`,
          backgroundPosition: getFlagPosition(i18n?.locale),
        }}
      />
      <div className="navbar-text">{i18n?.name}</div>
    </>
  );

  return (
    languages?.length > 0 && (
      <>
        <div className="d-none d-sm-block divider" />
        <Navbar.Text className="d-flex align-items-center">
          <NavDropdown title={title} className="nav-language">
            {languages.map((language) => (
              <NavDropdown.Item
                onClick={() => {
                  setI18n(language);
                  setLocalStorageLocale(language.locale);
                }}
                key={language.id}
              >
                <div className="d-flex align-items-center">
                  <div
                    className="flag flag-dropdown"
                    style={{
                      background: `url(${flagImage}) no-repeat`,
                      backgroundPosition: getFlagPosition(language?.locale),
                    }}
                  />
                  <div className="navbar-text"> {language.name}</div>
                </div>
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Navbar.Text>
      </>
    )
  );
});

LanguageSwitch.propTypes = {};

export default LanguageSwitch;
