import { request } from 'src/helpers/request';

export async function getLobbyPage({ playerToken, groupCode, brandCode }) {
  try {
    const url = `api/client/lobby-page?brandCode=${brandCode}&groupCode=${groupCode}&playerToken=${playerToken}`;
    return request.get(url);
  } catch (error) {
    return error.response;
  }
}

export async function getLanguages({ groupCode, brandCode }) {
  try {
    let url = 'api/client/lobby/languages';

    if (brandCode && groupCode) {
      url = `${url}?brandCode=${brandCode}&groupCode=${groupCode}`;
    }

    return request.get(url);
  } catch (error) {
    return error.response;
  }
}

export async function getI18n({ locale }) {
  try {
    const url = `api/client/lobby/i18n/${locale}`;
    return request.get(url);
  } catch (error) {
    return error.response;
  }
}
