import axios from 'axios';
import set from 'lodash/set';

const defaultHeaders = {};

const request = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.response.use((response) => response.data);

request.interceptors.request.use((config) => {
  if (defaultHeaders.locale) {
    set(config, ['headers', 'get', 'locale'], defaultHeaders.locale);
  }

  return config;
});

export { request };

export const setHeaderLocale = (locale) => {
  defaultHeaders.locale = locale;
};
