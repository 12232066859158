import { request } from 'src/helpers/request';

export async function listGames(filter) {
  try {
    const url = 'api/client/game/enter-lobby-page-get-all-games';
    return request.get(url, { params: filter });
  } catch (error) {
    return error.response;
  }
}

export async function getBalance(filter) {
  try {
    const url = 'api/client/player/balance';
    return request.get(url, { params: filter });
  } catch (error) {
    return error.response;
  }
}
