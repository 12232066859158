// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-language > a {
  display: flex;
  align-items: center;
}
.nav-language .dropdown-item:active {
  background-color: none;
}
.nav-language .flag-dropdown {
  display: flex;
  align-items: center;
}
.nav-language .flag {
  object-fit: cover;
  width: 24px;
  height: 18px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/conponents/Shared/Header/LanguageSwitch/index.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAGE;EACE,sBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAHJ","sourcesContent":[".nav-language {\n  >a {\n    display: flex;\n    align-items: center;\n  }\n\n  .dropdown-item:active {\n    background-color: none;\n  }\n\n  .flag-dropdown {\n    display: flex;\n    align-items: center;\n  }\n\n  .flag {\n    object-fit: cover;\n    width: 24px;\n    height: 18px;\n    margin-right: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
