// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-container {
  margin: 42px auto;
  max-width: 1456px;
  padding: 0 40px;
}
@media (max-width: 576px) {
  .home-page-container {
    padding: 0 16px;
    margin: 24px auto;
  }
}
.home-page-container .row {
  --bs-gutter-y: 1.25rem;
}
@media (max-width: 576px) {
  .home-page-container .row > * {
    --bs-gutter-y: 0.5rem;
    padding: 0 4px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/index.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AACF;AACE;EALF;IAMI,eAAA;IACA,iBAAA;EAEF;AACF;AAAE;EACE,sBAAA;AAEJ;AACM;EADF;IAEI,qBAAA;IACA,cAAA;EAEN;AACF","sourcesContent":[".home-page-container {\n  margin: 42px auto;\n  max-width: 1456px;\n  padding: 0 40px;\n\n  @media (max-width: 576px) {\n    padding: 0 16px;\n    margin: 24px auto;\n  }\n\n  .row {\n    --bs-gutter-y: 1.25rem;\n\n    & > * {\n      @media (max-width: 576px) {\n        --bs-gutter-y: 0.5rem;\n        padding: 0 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
