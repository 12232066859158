/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import noImage from 'src/assets/images/no-image.jpeg';
import { DEFAULT_CONFIGS } from 'src/constants';
import { useAppContext } from 'src/context/appContext';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';
import '../index.scss';
import useTranslate from 'src/hooks/useTranslate';
import { useLanguageContext } from 'src/context/languageContext';

const onError = (e) => {
  e.target.src = noImage;
};

function AllGames({ games }) {
  const {
    lobbyPageUrl,
    player: { currency: currencyCode },
  } = useAppContext();

  const { i18n } = useLanguageContext();

  const groupCode = useSearchQueryParams(
    'groupCode',
    DEFAULT_CONFIGS.groupCode,
  );
  const brandCode = useSearchQueryParams(
    'brandCode',
    DEFAULT_CONFIGS.brandCode,
  );

  const playerToken = useSearchQueryParams(
    'playerToken',
    DEFAULT_CONFIGS.playerToken,
  );

  const handleOnClick = (e) => {
    const { url } = e.target.dataset;
    const language = i18n?.code;

    if (url) {
      let redirectUrl = lobbyPageUrl;
      if (window.location.search) {
        redirectUrl += window.location.search;
      }

      const playUrl = `${url}?playerToken=${playerToken}&brandCode=${brandCode}&groupCode=${groupCode}&language=${language}&currencyCode=${currencyCode}&redirectUrl=${encodeURIComponent(
        redirectUrl,
      )}`;

      window.open(playUrl, '_blank');
    }
  };

  const translate = useTranslate();

  return (
    <div className="row">
      {games.length > 0 &&
        games.map((item) => (
          <div
            key={item.gameId}
            className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4"
          >
            <div className="lobby-card-container position-relative">
              {Boolean(item.isHotGame || item.isNewGame) && (
                <div className="lobby-card-label-container">
                  {Boolean(item.isNewGame) && <NewLabel />}
                  {Boolean(item.isHotGame) && <HotLabel />}
                </div>
              )}
              <Card>
                <Card.Img
                  variant="top"
                  src={item.preview || noImage}
                  height="100%"
                  width="100%"
                  onError={onError}
                  loading="lazy"
                />
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Text>{item.desc}</Card.Text>
                  <Button
                    variant="danger"
                    data-url={item.playUrl}
                    onClick={handleOnClick}
                  >
                    {translate('common.text.playNow', 'Play Now')}
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        ))}
    </div>
  );
}

AllGames.propTypes = {
  games: PropTypes.array,
};

function HotLabel() {
  const translate = useTranslate();

  return <div className="lobby-card-label hot-label">{translate('hot')}</div>;
}

function NewLabel() {
  const translate = useTranslate();
  return <div className="lobby-card-label new-label">{translate('new')}</div>;
}

export default AllGames;
