import React from 'react';
import { Switch } from 'react-router-dom';
import { ROUTES } from '../constants';
import GuestRoute from './GuestRoute';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';

function Routes() {
  return (
    <Switch>
      <GuestRoute
        exact
        path={ROUTES.ROOT_PATH}
        component={HomePage}
      />
      <GuestRoute
        path='*'
        component={NotFoundPage}
      />
    </Switch>
  );
}

export default Routes;
