import { useCallback } from 'react';
import { useLanguageContext } from 'src/context/languageContext';

const useTranslate = () => {
  const { translations } = useLanguageContext();

  const translate = useCallback(
    (key, initValue) => translations[key] || initValue,
    [translations],
  );

  return translate;
};

export default useTranslate;
