import md5 from 'md5';

export const getFlagPosition = (item) => {
  switch (item) {
    case 'en':
      return '-240px -123px';
    case 'th':
      return '-240px -315px';
    case 'cn':
      return '-336px -76px';
    default:
      return '';
  }
};

export const isObject = (o) => o != null && typeof o === 'object';

export const genUUID = (target, scope) => {
  const hashStatus = md5(`${target}@${scope}`);
  return `${hashStatus.substr(0, 8)}-${hashStatus.substr(
    8,
    4,
  )}-${hashStatus.substr(12, 4)}-${hashStatus.substr(
    16,
    4,
  )}-${hashStatus.substr(20, 12)}`;
};
