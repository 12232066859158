// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-header {
  background-color: white;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  .lobby-header .navbar {
    height: 100%;
  }
}
.lobby-header .navbar a {
  text-decoration: none;
}
.lobby-header .navbar-toggler:focus {
  box-shadow: none;
}
.lobby-header .--custom-container {
  height: 100%;
  margin: 0 auto;
  max-width: 1456px;
  padding: 0 40px;
}
@media (max-width: 576px) {
  .lobby-header .--custom-container {
    padding: 0 16px;
  }
}
.lobby-header .divider {
  width: 2px;
  height: 20px;
  margin: 0 10px;
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/conponents/Shared/Header/index.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,6BAAA;AACF;AAEI;EADF;IAEI,YAAA;EACJ;AACF;AACI;EACE,qBAAA;AACN;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,YAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;AAFJ;AAII;EANF;IAOI,eAAA;EADJ;AACF;AAIE;EACE,UAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;AAFJ","sourcesContent":[".lobby-header {\n  background-color: white;\n  border-bottom: 1px solid #ddd;\n\n  .navbar {\n    @media (min-width: 769px) {\n      height: 100%;\n    }\n\n    a {\n      text-decoration: none;\n    }\n  }\n\n  .navbar-toggler:focus {\n    box-shadow: none;\n  }\n\n  .--custom-container {\n    height: 100%;\n    margin: 0 auto;\n    max-width: 1456px;\n    padding: 0 40px;\n\n    @media (max-width: 576px) {\n      padding: 0 16px;\n    }\n  }\n\n  .divider {\n    width: 2px;\n    height: 20px;\n    margin: 0 10px;\n    background-color: #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
