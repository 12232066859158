import React, { useEffect, useState } from 'react';
import HomeContainer from 'src/containers/HomeContainer';
import { useLanguageContext } from 'src/context/languageContext';
import Spinner from 'react-bootstrap/Spinner';
import './index.scss';

function HomePage() {
  const { i18n } = useLanguageContext();

  const [loading, setLoading] = useState(!i18n?.locale);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading((_) => false);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-page-container">
      {loading ? (
        <div className="home-page__wrapper --loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <HomeContainer />
      )}
    </div>
  );
}

export default HomePage;
