// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page__wrapper .search-container {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  position: relative;
}
.home-page__wrapper .search-container .search-icon {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  padding-bottom: 2px;
}
.home-page__wrapper .search-container input {
  padding-left: 36px;
  height: 48px;
}
.home-page__wrapper .search-container input:hover, .home-page__wrapper .search-container input:focus {
  outline: none;
  box-shadow: none;
}
.home-page__wrapper.--loading {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
.home-page__tabs-container {
  margin-top: 42px;
}
@media (max-width: 576px) {
  .home-page__tabs-container {
    margin-top: 24px;
  }
}
.home-page__tabs-item__title {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3px;
  font-size: 14px;
  cursor: pointer;
}
.home-page__tabs-item__title span {
  margin-top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/containers/HomeContainer/index.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AADN;AAGM;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;EACA,mBAAA;AADR;AAIM;EACE,kBAAA;EACA,YAAA;AAFR;AAIQ;EAEE,aAAA;EACA,gBAAA;AAHV;AAOI;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AALN;AAUI;EACE,gBAAA;AARN;AASM;EAFF;IAGI,gBAAA;EANN;AACF;AAUM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;AARR;AASQ;EACE,eAAA;AAPV","sourcesContent":[".home-page {\n  &__wrapper {\n    .search-container {\n      width: 100%;\n      max-width: 480px;\n      margin: 0 auto;\n      position: relative;\n\n      .search-icon {\n        position: absolute;\n        z-index: 10;\n        top: 50%;\n        left: 12px;\n        transform: translateY(-50%);\n        padding-bottom: 2px;\n      }\n\n      input {\n        padding-left: 36px;\n        height: 48px;\n\n        &:hover,\n        &:focus {\n          outline: none;\n          box-shadow: none;\n        }\n      }\n    }\n    &.--loading {\n      display: flex;\n      justify-content: center;\n      margin-top: 20vh;\n    }\n  }\n\n  &__tabs {\n    &-container {\n      margin-top: 42px;\n      @media (max-width: 576px) {\n        margin-top: 24px;\n      }\n    }\n\n    &-item {\n      &__title {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        column-gap: 3px;\n        font-size: 14px;\n        cursor: pointer;\n        span {\n          margin-top: 2px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
