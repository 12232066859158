/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import { CashStack, PersonCircle } from 'react-bootstrap-icons';
import { getBalance } from 'src/services/apis/game';
import logoImg from 'src/assets/images/logo.png';
import { useAppContext } from 'src/context/appContext';
import { DEFAULT_CONFIGS } from 'src/constants';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';

import './index.scss';
import LanguageSwitch from './LanguageSwitch';

function Header() {
  const groupCode = useSearchQueryParams(
    'groupCode',
    DEFAULT_CONFIGS.groupCode,
  );
  const brandCode = useSearchQueryParams(
    'brandCode',
    DEFAULT_CONFIGS.brandCode,
  );
  const playerToken = useSearchQueryParams(
    'playerToken',
    DEFAULT_CONFIGS.playerToken,
  );

  const { setPlayerCurrency } = useAppContext();

  const fetchData = async () => {
    const player = await getBalance({
      playerToken,
      groupCode,
      brandCode,
    });

    setCurrency(player.currency);
    setPlayerCurrency(player.currency);
    setName(player.nativeId);
    setBalance(player.balance);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [name, setName] = useState('Username');
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState('USD');

  return (
    <div className="lobby-header">
      <div className="container --custom-container">
        <Navbar expand="lg">
          <Container fluid className="p-0">
            <Navbar.Brand href={window?.location?.href}>
              <img src={logoImg} alt="logo" width={74} height={46} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" />
              <div className="justify-content-end">
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex align-items-center justify-content-between justify-content-sm-start">
                    <Navbar.Text className="d-flex align-items-center">
                      <PersonCircle />
                      <Button variant="link" href="#">
                        {name}
                      </Button>
                    </Navbar.Text>
                    <div className="divider" />
                    <Navbar.Text className="d-flex align-items-center">
                      <CashStack />
                      <span className="mx-2">
                        {balance} {currency}
                      </span>
                    </Navbar.Text>
                  </div>
                  <div className="d-flex align-items-center">
                    <LanguageSwitch />
                  </div>
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
