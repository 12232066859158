/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
} from 'react-bootstrap';

import notFoundImage from '../../assets/images/not-found.svg';
import './index.scss';

function NotFoundPage() {
  const history = useHistory();

  const handleOnClick = () => {
    history.replace('/');
  };

  return (
    <div className="notfound-page">
      <Container>
        <div className="text-center">
          <img src={notFoundImage} alt="Not found" className="image" />

          <p className="font-gilroy">Oops! The page you are looking for does not exist.</p>

          <Button
            variant="primary"
            onClick={handleOnClick}
          >
            Back to home
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default NotFoundPage;
