/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

function useSearchQueryParams(field, defaultValue) {
  const search = window?.location?.search;
  const searchDecoded = decodeURIComponent(search);

  useEffect(() => {
    if (search !== searchDecoded) {
      window.history.pushState('', document.title, searchDecoded);
    }
  }, []);

  const values = new URLSearchParams(searchDecoded);

  if (field || defaultValue) {
    return values.get(field) || defaultValue;
  }

  return values;
}

export default useSearchQueryParams;
