import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from 'src/context/appContext';
import { LanguageContextProvider } from 'src/context/languageContext';
import Routes from './routes';
import './App.scss';
import Head from './conponents/Head';

function App() {
  return (
    <LanguageContextProvider>
      <Head />
      <AppContextProvider>
        <Router>
          <Routes />
        </Router>
      </AppContextProvider>
    </LanguageContextProvider>
  );
}

export default App;
