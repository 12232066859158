import React from 'react';
import { Helmet } from 'react-helmet';
import { useLanguageContext } from 'src/context/languageContext';

const DEFAULT_TITLE = 'Lobby - Naga Games Slots';

function Head() {
  const {
    i18n: { locale },
  } = useLanguageContext();

  return (
    <Helmet>
      <title>
        {{
          en: 'Lobby - Naga Games Slots',
          cn: '大厅 - Naga Games 老虎机',
          th: 'ล็อบบี้ - สล็อตเกมนากา',
        }[locale] || DEFAULT_TITLE}
      </title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@800&family=Roboto&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export default Head;
